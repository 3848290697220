<template>
  <v-container fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-form ref="loginForm" v-model="valid" lazy-validation>
          <v-card class="elevation-12" outlined color="blue lighten-2">
            <v-toolbar dark color="blue accent-4">
              <v-spacer />
              <!-- 
              <v-avatar rounded size="75">
                <v-img src="../assets/fnlogo.png"></v-img>
              </v-avatar> -->

              <v-toolbar-title>โปรแกรมบริหารจัดการลูกค้า</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-card-text>
              <v-form
                ref="loginForm"
                v-model="valid"
                lazy-validation
                @submit.prevent="onSubmit"
              >
                <v-text-field
                  label="ชื่อผู้ใช้"
                  name="txtusername"
                  ref="txtusername"
                  v-model="form.username"
                  :rules="[rules.user_required]"
                  prepend-icon="mdi-account"
                  type="text"
                  color="blue accent-4"
                  required
                />
                <v-text-field
                  label="รหัสผ่าน"
                  name="txtpassword"
                  ref="txtpassword"
                  v-model="form.password"
                  :rules="[rules.pass_required]"
                  prepend-icon="mdi-lock"
                  type="password"
                  color="blue accent-4"
                />
                <!-- @keydown.enter.stop.prevent -->
                <v-divider light></v-divider>
                <v-card-actions>
                  <v-btn
                    class="blue accent-4"
                    block
                    large
                    outlined
                    color="white"
                    type="submit"
                    :disabled="!valid"
                    @click="onSubmit()"
                    >
                    <!-- @touchstart="touch = true" -->
                  
                    <!-- @click.prevent="login()" -->
                    เข้าสู่ระบบ

                    <v-icon class="ml-3">mdi-account-arrow-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card-text>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
// import {mapActions } from 'vuex'

export default {
  name: "Login",
  components: {},
  data: () => ({
    valid: true,
    form: {
      username: "",
      password: "",
      error: false,
    },
    touch: false,
    rules: {
      user_required: (value) => !!value || "กรอกชื่อผู้ใช้.",
      pass_required: (value) => !!value || "กรอกรหัสผ่าน.",
      //   counter: (value) => value.length <= 20 || "Max 20 characters",
      //   email: (value) => {
      //     const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      //     return pattern.test(value) || "Invalid e-mail.";
      //   },
    },
  }),
  mounted() {
    this.$refs.txtusername.focus();
  },
  methods: {
    // ...mapActions('account', ['LOGIN1122']),
    onSubmit() {
      if (this.$refs.loginForm.validate()) {
        if (this.form.username != "" && this.form.password != "") {
          this.do_login(this.form.username, this.form.password);
        } else {
          if (this.form.username == "") {
            this.$refs.txtusername.focus();
          } else {
            this.$refs.txtpassword.focus();
          }
        }
      }
    },

    async do_login(p_username, p_password) {
      let credentials = {
        username: p_username,
        password: p_password,
      };

      this.$store.dispatch("auth/login", credentials).then(
        (response) => {
        //   localStorage.setItem("touch", this.touch);
          if (response == true) {
            this.$router.push("/Menu_Data");
          } else {
            this.$router.push("/CreatePincode");
          }
        },
        (error) => {
          switch (error) {
            case "Error01":
              alert("Error01");
              break;
            case "PW_INCORRECT":
              this.$alert("รหัสผ่านไม่ถูกต้อง.", "ผิดพลาด", "error").then(
                () => {
                  (this.form.password = ""), this.$refs.txtpassword.focus();
                }
              );
              break;
            case "USER_NOTFOUND":
              this.$alert("ชื่อผู้ใช้ไม่ถูกต้อง.", "ผิดพลาด", "error").then(
                () => {
                  this.$refs.txtusername.focus();
                }
              );
              break;
          }
          //this.login_message(error);
        }
      );
    }, //end do login
  },
};
</script>

<style></style>
